import { useEffect, useState, useRef } from "react";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import "../Styles/AugioFeatures.css";

export const AugioFeatures = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const features = [
    {
      image: "/mock_for_web_12.png",
      title: "STATE CHARTS",
      description: "Listen to the top songs in every state.",
    },
    {
      image: "/mock_for_web_15.png",
      title: "REGION FEEDS",
      description: "Discover music from any region the moment it drops.",
    },
    {
      image: "/mock_for_web_14.png",
      title: "PERSONAL FEED",
      description:
        "Follow artists and keep their latest music all in one place.",
    },
    {
      image: "/mock_for_web_16.png",
      title: "RECOMMEND SONGS",
      description: "Send songs directly to friends in-app.",
    },
    {
      image: "/mock_for_web_11.png",
      title: "PROMOTION TOOLS",
      description: "Boost your music with built-in promo features.",
    },
    {
      image: "mock_for_web_13.png",
      title: "AUGIO TOP 100",
      description: "When it comes to indie music, this is the chart to be.",
    },
  ];

  const next = () => setCurrentIndex((prev) => (prev + 1) % features.length);
  const prev = () =>
    setCurrentIndex((prev) => (prev - 1 + features.length) % features.length);

  useEffect(() => {
    intervalRef.current = setInterval(next, 4000);
    return () => clearInterval(intervalRef.current!);
  }, []);

  const getFeatureAt = (offset: number) => {
    return features[
      (currentIndex + offset + features.length) % features.length
    ];
  };

  return (
    <section className="mobile-app-preview-section">
      <h2 className="subheader-text-small">
        DESIGNED <br />
        FOR DISCOVERY
      </h2>

      <div className="carousel-outer">
        <div className="carousel-3up-track" key={currentIndex}>
          {[-1, 0, 1].map((offset) => {
            const feature = getFeatureAt(offset);
            const isCenter = offset === 0;
            return (
              <div
                key={offset}
                className={`carousel-3up-item ${isCenter ? "center" : "side"}`}
              >
                <div className="image-wrapper">
                  <img src={feature.image} alt={feature.title} />
                  {!isCenter && <div className="overlay" />}
                </div>
                {isCenter && (
                  <div className="feature-info">
                    <h2 className="feature-title-text">{feature.title}</h2>
                    <p className="feature-subtext">
                      {feature.description}
                    </p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>

      <div className="carousel-controls">
        <RiArrowLeftSLine className="carousel-arrow" onClick={prev} />
        <RiArrowRightSLine className="carousel-arrow" onClick={next} />
      </div>
    </section>
  );
};
