// REACT IMPORTS
import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

// ICONS
import { BsLinkedin } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import {
  FaTiktok,
  FaLink,
  FaMedium,
  FaYoutube,
  FaInstagramSquare,
} from "react-icons/fa";

// COMPONENTS
import { logCustomEvent } from "../Utilities/EventConfig";

//STYLES
import "../Styles/Footer.css";

export const Footer = () => {
  const logoUrl: string = "/Augio_FullType_Logo_latest.png";

  return (
    <footer>
      <div className="footer-container">
        <div className="footer-top-group">
          <img
            className="footer-text-logo"
            src={logoUrl}
            alt="augio-colorized-mock"
          />

          <div>
            <Link className="footer-button-link fbl-login" to="/login">
              Login
            </Link>
            <Link className="footer-button-link fbl-register" to="/register">
              Register
            </Link>
            <a
              className="footer-button-link fbl-mobile"
              href="https://apps.apple.com/us/app/augio-music-discovery/id6736578132"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                logCustomEvent("mobile_app_dl_button_click", {
                  button_name: "Mobile App Download",
                  page_location: window.location.pathname,
                })
              }
            >
              Mobile App
            </a>
          </div>
        </div>

        <hr className="footer-divider"></hr>

        <div className="footer-bottom-group">
          <div className="footer-company-resources">
            <div className="footer-links-group">
              <h6>COMPANY</h6>
              <p className="footer-link">
                <Link
                  className="footer-link clickable-link"
                  target="_blank"
                  to="https://augio.medium.com/"
                >
                  Blog
                </Link>
              </p>

              <p className="footer-link">Press: info@augio.io</p>
              <p className="footer-link">Support: support@augio.io</p>
            </div>

            <div className="footer-links-group">
              <h6>RESOURCES</h6>
              <Link
                className="footer-link quick-link"
                target="_blank"
                to="/terms"
              >
                Terms
              </Link>
              <Link
                className="footer-link quick-link"
                target="_blank"
                to="/membership-terms"
              >
                Membership Agreement
              </Link>

              <Link
                className="footer-link quick-link"
                target="_blank"
                to="/privacy-policy"
              >
                Privacy Policy
              </Link>

              <Link className="footer-link quick-link" to="/faq">
                FAQ
              </Link>
            </div>
          </div>

          <div className="footer-social-group">
            <div className="footer-icons">
              <a
                className="footer-link"
                href="https://www.instagram.com/augio.io"
                target="_blank"
              >
                <AiFillInstagram className="footer-icon"></AiFillInstagram>
              </a>
              <a
                className="footer-link quick-link"
                href="https://www.linkedin.com/company/augiomusic"
                target="_blank"
              >
                <BsLinkedin className="footer-icon"></BsLinkedin>
              </a>
              <a
                className="footer-link"
                href="https://www.tiktok.com/@augio.io"
                target="_blank"
              >
                <FaTiktok className="footer-icon"></FaTiktok>
              </a>
              <a
                className="footer-link quick-link"
                href="https://www.youtube.com/@Augio-io"
                target="_blank"
              >
                <FaYoutube className="footer-icon"></FaYoutube>
              </a>
              <a
                className="footer-link quick-link"
                href="https://augio.medium.com/"
                target="_blank"
              >
                <FaMedium className="footer-icon"></FaMedium>
              </a>

              {/* 
                <a
                  className="footer-link quick-link"
                  href="https://www.twitter.com/augiomusic_io"
                  target="_blank"
                >
                  <BsTwitter className="footer-icon"></BsTwitter>
                </a>
                */}
            </div>

            <div className="augio-copyright">
              © 2025 AUGIO. All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
