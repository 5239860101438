import React, { useState, useEffect, useContext, useRef } from "react";
import "../Styles/AudioTrack.css";
import { FaRegHeart, FaHeart, FaPlay, FaPause } from "react-icons/fa";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { LikeTracksService } from "../Services/LikeTracksService";
import { Link } from "react-router-dom";
import { Track, AudioTrackProps, Playlist } from "../Types/Types";
import { AudioPlayerContext } from "../Context/AudioPlayerContext";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";
import { HiDotsVertical } from "react-icons/hi";
import { PlaylistService } from "../Services/PlaylistService";
import { BsPlusCircle } from "react-icons/bs";



export const AudioTrack = ({
  track,
  showChartPosition = false,
  feedIdentifier,
  feedTracks,
}: AudioTrackProps) => {
  const [showCredits, setShowCredits] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const {
    trackTitle,
    userName,
    artistName,
    genre,
    coverArtUrl,
    chartPosition,
    featureArtists,
    producers,
    songwriters,
    engineers,
    recordLabel
  } = track;

  const auth = getAuth();
  const [userId, setUserId] = useState<string | null>(null);
  const [showAddToPlaylist, setShowAddToPlaylist] = useState(false);
  const [playlists, setPlaylists] = useState<Playlist[]>([]);
  const [selectedPlaylistId, setSelectedPlaylistId] = useState<string | null>(
    null
  );
  const [showModal, setShowModal] = useState(false);
  const trackActionsModalRef = useRef<HTMLDivElement>(null);

  const {
    currentTrack,
    isPlaying: globalIsPlaying,
    togglePlayPause,
    playTrack,
    setCurrentDataSource,
    setPlaylist,
    setCurrentTime,
  } = useContext(AudioPlayerContext);

  const isCurrentAndPlaying =
    currentTrack && currentTrack.id === track.id && globalIsPlaying;

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUserId(user ? user.uid : null);
    });
    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    const checkIfLiked = async () => {
      if (userId && track.id) {
        const liked = await LikeTracksService.isTrackLikedByUser(
          userId,
          track.id
        );
        setIsLiked(liked);
      }
    };
    checkIfLiked();
  }, [track.id, userId]);

  const handleEllipsisClick = () => {
    setShowModal(true);
    setShowAddToPlaylist(false);
  };

  const handleAddToPlaylistClick = async () => {
    setShowAddToPlaylist(true);

    if (userId) {
      const userPlaylists = await PlaylistService.fetchPlaylists(userId);
      setPlaylists(userPlaylists as Playlist[]);
    }
  };

  const handleSelectPlaylist = async (playlistId: string) => {
    if (playlistId) {
      await PlaylistService.addTrackToPlaylist(playlistId, track.id);
      closeModal();
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setShowAddToPlaylist(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        trackActionsModalRef.current &&
        !trackActionsModalRef.current.contains(event.target as Node)
      ) {
        closeModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handlePlayPauseClick = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setPlaylist(feedTracks, feedIdentifier);

    if (currentTrack && currentTrack.id === track.id) {
      togglePlayPause();
    } else {
      setCurrentTime(0);
      await playTrack(track);

      const incrementPlayCount = httpsCallable(functions, "incrementPlayCount");
      try {
        const result = await incrementPlayCount({ trackId: track.id });
        console.log("Play count incremented:", result.data);
      } catch (error) {
        console.error("Error incrementing play count:", error);
      }
    }
  };

  const toggleCredits = () => setShowCredits(!showCredits);

  const toggleLike = async () => {
    if (!userId) {
      console.log("User is not logged in.");
      return;
    }

    const previousLikeStatus = isLiked;
    setIsLiked(!isLiked);

    try {
      if (previousLikeStatus) {
        await LikeTracksService.unlikeTrack(userId, track.id);
      } else {
        await LikeTracksService.likeTrack(userId, track.id);
      }
    } catch (error) {
      console.error("Failed to toggle like status:", error);
      setIsLiked(previousLikeStatus);
    }
  };

  const displayedTrackTitle =
    track.featureArtists && track.featureArtists.length > 0
      ? `${track.trackTitle} ft. ${track.featureArtists.join(", ")}`
      : track.trackTitle;

  const displayCreditsOrNA = (credits: string | string[] | undefined) => {
    if (!credits || (Array.isArray(credits) && credits.length === 0)) {
      return "N/A";
    }
    return Array.isArray(credits) ? credits.join(", ") : credits;
  };


  return (
    <div className="audio-track-container">
      <section className="audio-track-content">
        {showChartPosition && (
          <div className="audio-track-chart-position">{chartPosition}</div>
        )}
        <div
          className="cover-art-container"
          onClick={(e) => handlePlayPauseClick(e)}
        >
          <img
            className="audio-track-cover-art"
            src={coverArtUrl}
            alt="Cover Art"
          />
          {isCurrentAndPlaying ? (
            <FaPause className="play-pause-icon" />
          ) : (
            <FaPlay className="play-pause-icon" />
          )}
        </div>
        <div className="audio-track-details">
          <p className="audio-track-title audio-track-details-spacing">
            {displayedTrackTitle}
          </p>
          <p className="audio-track-artist audio-track-details-spacing">
            <Link to={`/${userName}`} className="user-profile-link">
              {artistName}
            </Link>
          </p>
          <p className="audio-track-genre audio-track-details-spacing">
            {genre}
          </p>
        </div>
        <div className="audio-track-actions-group">
          <button className="audio-track-credits-btn" onClick={toggleCredits}>
            {showCredits ? "HIDE CREDITS" : "VIEW CREDITS"}
          </button>
          <button className="like-audio-track-btn" onClick={toggleLike}>
            {isLiked ? <FaHeart /> : <FaRegHeart />}
          </button>
          <span>
            <HiDotsVertical
              className="audio-track-actions"
              onClick={handleEllipsisClick}
            />
          </span>

          {showModal && (
            <div className="track-actions-modal" ref={trackActionsModalRef}>
              <div className="modal-content">
                {!showAddToPlaylist ? (
                  <button
                    className="add-to-playlist-btn"
                    onClick={handleAddToPlaylistClick}
                  >
                    <BsPlusCircle className="playlist-plus-btn" />
                    Add to Playlist
                  </button>
                ) : (
                  <div className="add-to-playlist-dropdown">
                    {playlists.length > 0 ? (
                      playlists.map((playlist) => (
                        <div
                          key={playlist.id}
                          className="playlist-item"
                          onClick={() => handleSelectPlaylist(playlist.id)}
                        >
                          {playlist.playlistName}
                        </div>
                      ))
                    ) : (
                      <p>No playlists available</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </section>

      {showCredits && (
        <div className="credits-section">
          <p className="audio-track-credits">
            <span className="credit-title">Producers:</span>
            <span className="credit-info">{displayCreditsOrNA(producers)}</span>
          </p>
          <p className="audio-track-credits">
            <span className="credit-title">Songwriters:</span>
            <span className="credit-info">
              {displayCreditsOrNA(songwriters)}
            </span>
          </p>
          <p className="audio-track-credits">
            <span className="credit-title">Engineers:</span>
            <span className="credit-info">{displayCreditsOrNA(engineers)}</span>
          </p>
          <p className="audio-track-credits">
            <span className="credit-title">Label:</span>
            <span className="credit-info">
              {displayCreditsOrNA(recordLabel)}
            </span>
          </p>
        </div>
      )}
      
    </div>
  );
};
