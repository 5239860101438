// REACT IMPORTS
import React, { useEffect, useRef, useState, forwardRef } from "react";

// EXTERNAL PACKAGES
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaRegFileAlt, FaRegTrashAlt } from "react-icons/fa";

// STYLES
import "../Styles/UploadForm.css";
import { ProgressSpinner } from "../GlobalStyles/ProgressSpinner";

// UTILITIES
import { sortedGenres } from "../Utilities/StaticFormData";
import { logCustomEvent } from "../Utilities/EventConfig";

// SERVICES
import { UploadSingleService } from "../Services/UploadSingleService";
import {
  validateAudioFile,
  validateCoverArt,
  validateGenre,
  validateTrackTitle,
  validateUploadTermsCheckBox,
} from "../Services/UploadValidation";

// TYPES
import {
  DatePickerInputProps,
  ResizeAndCropImageParams,
  SingleUploadErrorsState,
  SingleUploadFormData,
} from "../Types/Types";


const CustomDatePickerInput = forwardRef<
  HTMLInputElement,
  DatePickerInputProps
>(({ value, onClick }, ref) => (
  <input
    className="upload-form-input"
    onClick={onClick}
    value={value}
    ref={ref}
    readOnly
  />
));

export const UploadSingleForm = ({
  show,
  onClose,
  uploadType,
}: {
  show: boolean;
  onClose: () => void;
  uploadType: string;
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCoverArtUrl, setSelectedCoverArtUrl] = useState<string | null>(
    null
  );
  const [uploadProgress, setUploadProgress] = useState(0);

  const [formData, setFormData] = useState<SingleUploadFormData>({
    coverArt: null,
    trackTitle: "",
    featureArtists: "",
    genre: "",
    producers: "",
    songwriters: "",
    engineers: "",
    audioFile: null,
    uploadType: uploadType,
    releaseDate: new Date(),
    agreeToTerms: false,
    recordLabel: "",
  });

  const [errors, setErrors] = useState<SingleUploadErrorsState>({
    coverArt: "",
    trackTitle: "",
    featureArtists: "",
    genre: "",
    producers: "",
    songwriters: "",
    engineers: "",
    audioFile: "",
    terms: "",
    recordLabel: "",
  });

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    fieldName: string
  ) => {
    const value = event.target.value;
    setFormData((prevFormData) => ({ ...prevFormData, [fieldName]: value }));
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    // Validate the single formData object
    const coverArtError = validateCoverArt(formData.coverArt);
    const trackTitleError = validateTrackTitle(formData.trackTitle);
    const genreError = validateGenre(formData.genre);
    const audioFileError = validateAudioFile(formData.audioFile);
    const termsError = validateUploadTermsCheckBox(formData.agreeToTerms);

    // Construct the errors object
    const allErrors = {
      coverArt: coverArtError,
      trackTitle: trackTitleError,
      genre: genreError,
      audioFile: audioFileError,
      featureArtists: "", // Include validation for other fields if necessary
      producers: "",
      songwriters: "",
      engineers: "",
      terms: termsError,
      recordLabel: "",
    };

    // Determine if validation passed
    const validationPassed = Object.values(allErrors).every((value) => !value);

    // Update state to reflect collected errors
    console.log("All Errors Collected: ", allErrors);
    setErrors(allErrors);

    if (!validationPassed) {
      setIsLoading(false);
      return;
    }

    const uploadService = new UploadSingleService(setIsLoading);

    try {
      await uploadService.uploadMusic({
        ...formData,
        featureArtists: formData.featureArtists
          .split(",")
          .map((name) => name.trim())
          .filter((name) => name !== ""),
        producers: formData.producers
          .split(",")
          .map((name) => name.trim())
          .filter((name) => name !== ""),
        songwriters: formData.songwriters
          .split(",")
          .map((name) => name.trim())
          .filter((name) => name !== ""),
        engineers: formData.engineers
          .split(",")
          .map((name) => name.trim())
          .filter((name) => name !== ""),
      });
      console.log("Upload successful");
      logCustomEvent("Single Uploaded");
      onClose();
    } catch (error: any) {
      console.error("Upload failed:", error);
      logCustomEvent("Single Upload Failed");
      const message = error?.message || "Upload failed. Please try again.";
      alert(message);
    } finally {
      setIsLoading(false);
    }
  };

  const resizeAndCropImage = ({
    file,
    targetSize,
    callback,
  }: ResizeAndCropImageParams) => {
    const reader = new FileReader();
    reader.onload = (readerEvent) => {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = targetSize;
        canvas.height = targetSize;

        const ctx = canvas.getContext("2d");
        if (!ctx) {
          callback(null);
          return;
        }

        // Calculate the best scale to fit the square dimension
        const scale = Math.max(
          targetSize / image.width,
          targetSize / image.height
        );
        const scaledWidth = image.width * scale;
        const scaledHeight = image.height * scale;

        // Calculate the centering position on the canvas
        const dx = (targetSize - scaledWidth) / 2;
        const dy = (targetSize - scaledHeight) / 2;

        ctx.drawImage(image, dx, dy, scaledWidth, scaledHeight);

        canvas.toBlob((blob) => {
          callback(blob);
        }, "image/jpeg");
      };
      image.src = readerEvent.target?.result as string;
    };
    reader.readAsDataURL(file);
  };

  const handleFileSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldType: "coverArt" | "audioFile"
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];

      if (fieldType === "coverArt") {
        // Process the image to be square
        resizeAndCropImage({
          file: file,
          targetSize: 400, // Desired square dimension
          callback: (blob) => {
            if (blob) {
              // Convert Blob to File
              const newFile = new File([blob], "coverArt.jpg", {
                type: blob.type,
                lastModified: new Date().getTime(), // Optionally set the last modified time
              });

              const url = URL.createObjectURL(newFile);
              setSelectedCoverArtUrl(url);
              setFormData((prevFormData) => ({
                ...prevFormData,
                coverArt: newFile,
              }));
            } else {
              console.error("Failed to resize and crop the image.");
            }
          },
        });
      } else {
        // Handle audio file selection normally
        setFormData((prevFormData) => ({ ...prevFormData, [fieldType]: file }));
      }
    }
  };

  const handleDeleteAudioFile = () => {
    setFormData((prevFormData) => ({ ...prevFormData, audioFile: null }));
  };

  const handleDateChange = (date: Date | null) => {
    setFormData((prevFormData) => ({ ...prevFormData, releaseDate: date }));
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClickOnDragDropArea = () => {
    // Programmatically click the hidden file input
    fileInputRef.current?.click();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false); // Update the dragging state

    const files = e.dataTransfer.files;
    if (files.length > 0) {
      // Assuming you only want the first file if multiple are dropped
      const file = files[0];
      // Update the formData with the dropped file, for example, as the audioFile
      setFormData({ ...formData, audioFile: file });
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      agreeToTerms: event.target.checked,
    }));
  };

  useEffect(() => {
    return () => {
      if (selectedCoverArtUrl) {
        URL.revokeObjectURL(selectedCoverArtUrl);
      }
    };
  }, [selectedCoverArtUrl]);

  return (
    <div className={`upload-form-container ${show ? "show" : ""}`}>
      <form className="upload-form" onSubmit={handleFormSubmit}>
        <div className="track-form-group">
          <div className="upload-form-top-rows">
            {/* Cover Art Upload */}
            <div className="cover-art-upload-container">
              <div className="cover-art-box">
                {/* Hidden File Input for Cover Art */}
                <input
                  id="coverArt"
                  name="coverArt"
                  type="file"
                  onChange={(e) => handleFileSelect(e, "coverArt")}
                  style={{ display: "none" }}
                />
                {/* Display selected cover art */}
                {selectedCoverArtUrl && (
                  <img
                    src={selectedCoverArtUrl}
                    alt="Selected Cover Art"
                    style={{ width: "150px", height: "150px" }} // Adjusted to be square
                  />
                )}
              </div>
              <label htmlFor="coverArt" className="add-cover-art-btn">
                Add Cover Art
              </label>
              {errors.coverArt && (
                <div className="error-message">{errors.coverArt}</div>
              )}
            </div>

            <div className="form-rows-container">
              <div className="upload-form-input-group">
                {/* Track Title */}
                <div className="upload-label-input-group">
                  <label className="upload-form-label">Track Title</label>
                  <input
                    name="trackTitle"
                    value={formData.trackTitle}
                    onChange={(e) => handleInputChange(e, "trackTitle")}
                    className="upload-form-input"
                  />
                  {errors.trackTitle && (
                    <span className="error-message">{errors.trackTitle}</span>
                  )}
                </div>

                {/* Feature Artists */}
                <div className="upload-label-input-group">
                  <label className="upload-form-label">Feature Artists</label>
                  <input
                    name="featureArtists"
                    value={formData.featureArtists}
                    onChange={(e) => handleInputChange(e, "featureArtists")}
                    className="upload-form-input"
                    placeholder="Add names separated by comma"
                  />
                  {errors.featureArtists && (
                    <span className="error-message">
                      {errors.featureArtists}
                    </span>
                  )}
                </div>
              </div>

              <div className="upload-form-input-group">
                {/* Producers */}
                <div className="upload-label-input-group">
                  <label className="upload-form-label">Producers</label>
                  <input
                    name="producers"
                    value={formData.producers}
                    onChange={(e) => handleInputChange(e, "producers")}
                    className="upload-form-input"
                    placeholder="Add names separated by comma"
                  />
                  {errors.producers && (
                    <span className="error-message">{errors.producers}</span>
                  )}
                </div>
                {/* Songwriters */}
                <div className="upload-label-input-group">
                  <label className="upload-form-label">Songwriters</label>
                  <input
                    name="songwriters"
                    value={formData.songwriters}
                    onChange={(e) => handleInputChange(e, "songwriters")}
                    className="upload-form-input"
                    placeholder="Add names separated by comma"
                  />
                  {errors.songwriters && (
                    <span className="error-message">{errors.songwriters}</span>
                  )}
                </div>
              </div>
              <div className="upload-form-input-group">
                {/* Engineers */}
                <div className="upload-label-input-group">
                  <label className="upload-form-label">Engineers</label>
                  <input
                    name="engineers"
                    value={formData.engineers}
                    onChange={(e) => handleInputChange(e, "engineers")}
                    className="upload-form-input"
                    placeholder="Add names separated by comma"
                  />
                  {errors.engineers && (
                    <span className="error-message">{errors.engineers}</span>
                  )}
                </div>

                {/* Genre Selection */}
                <div className="upload-label-input-group">
                  <label className="upload-form-label">Genre</label>
                  <select
                    name="genre"
                    value={formData.genre}
                    onChange={(e) => handleInputChange(e, "genre")}
                    className="upload-form-input"
                  >
                    <option value="">Select Genre</option>
                    {sortedGenres.map((genre) => (
                      <option key={genre.genreID} value={genre.genreName}>
                        {genre.genreName}
                      </option>
                    ))}
                  </select>

                  {errors.genre && (
                    <span className="error-message">{errors.genre}</span>
                  )}
                </div>
              </div>
              <div className="upload-form-input-group">
                {/* Record Label */}
                <div className="upload-label-input-group">
                  <label className="upload-form-label">Label</label>
                  <input
                    name="recordLabel"
                    value={formData.recordLabel}
                    onChange={(e) => handleInputChange(e, "recordLabel")}
                    className="upload-form-input-custom"
                    placeholder="If your track is released under a record label you can add it here"
                  />
                  {errors.recordLabel && (
                    <span className="error-message">{errors.recordLabel}</span>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Release Date */}
          {/* 
        <div className="upload-label-input-group">
          <label className="upload-form-label">Release Date</label>
          <DatePicker
            selected={formData.releaseDate}
            onChange={(date) => handleDateChange(date)}
            minDate={new Date()}
            customInput={<CustomDatePickerInput />}
          />
        </div>
        */}

          {/* Audio File Upload */}
          <div className="upload-form-file">
            <label
              htmlFor="audioFileInput"
              className="upload-form-add-audio-btn"
            >
              Add Audio File
            </label>
            <input
              id="audioFileInput"
              type="file"
              onChange={(e) => handleFileSelect(e, "audioFile")}
              style={{ display: "none" }}
            />
            {formData.audioFile && (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <FaRegFileAlt className="upload-form-file-icon" />
                <p className="upload-form-filename">
                  {formData.audioFile.name}
                </p>
                <button
                  className="upload-form-delete-file-btn"
                  onClick={handleDeleteAudioFile}
                  type="button"
                >
                  <FaRegTrashAlt className="upload-form-trash-icon" />
                </button>
              </div>
            )}
            {errors.audioFile && (
              <span className="error-message">{errors.audioFile}</span>
            )}
          </div>

          <div className="upload-terms-container">
            <input
              type="checkbox"
              checked={formData.agreeToTerms}
              onChange={handleCheckboxChange}
            />
            <span className="upload-terms-text">
              By checking this box I acknowledge I own all rights required to
              upload this music
            </span>
          </div>
          {errors.terms && (
            <span className="error-message">{errors.terms}</span>
          )}

          {/* Drag and Drop Area */}
          <div
            className={`drag-drop-area ${isDragging ? "dragging" : ""}`}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={handleClickOnDragDropArea}
          >
            {isDragging ? "Drop file here..." : "Drag and drop file here"}
          </div>

          <div className="upload-form-btn-group">
            <button
              type="button"
              className="close-upload-form-btn"
              onClick={onClose}
            >
              Close
            </button>
            <button
              type="submit"
              className="save-upload-btn"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {isLoading ? (
                <>
                  <ProgressSpinner percentage={uploadProgress} />
                </>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
