//REACT IMPORTS
import React from "react";
import ReactDOM from "react-dom/client";

//COMPONENTS
import App from "./App";
import reportWebVitals from "./reportWebVitals";

//STYLES
import "./index.css";

//SENTRY IMPORTS
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://2b1fc6f0ff96e7116598fc5814fddf56@o4509081000935424.ingest.us.sentry.io/4509081002311680",
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
