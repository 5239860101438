//REACT IMPORTS
import { useEffect, useState } from "react";

//STYLES
import "../Styles/AugioHighlights.css";

export const AugioHighlights = () => {
  const mapRegionImage: string = "/map_region_image.png";
  const regionAppMockup: string = "/region_mockup_for_web copy.png";
  const [currentIndex, setCurrentIndex] = useState(0);

  const mockupFeature = [
    {
      image: "/region_mockup_for_web copy.png",
      title: "REGIONS",
      header: "Discover Music by Region",
      description:
        "Explore region feeds to discover music from across the country.",
      iconImage: "/map_region_image.png",
    },
    {
      image: "/mock_app_charts.png",
      title: "STATE CHARTS",
      header: "The Hottest Music in Every State",
      description: "Discover rising stars and top songs in every state.",
      iconImage: "/state_chart_icon.png",
    },
    {
      image: "/mock_app_feed.png",
      title: "PERSONAL FEED",
      header: "Stay Tuned In",
      description: "Keep up with your favorite artists latest releases.",
      iconImage: "/personalfeedicon.png",
    },
    {
      image: "/mock_app_top100.png",
      title: "AUGIO TOP 100",
      header: "What's Hot Nationwide",
      description: "The hottest artists and music trending nationwide.",
      iconImage: "/top100icon.png",
    },
    {
      image: "/mockup_app_songrecs.png",
      title: "SONG RECOMMENDING",
      header: "Share the Music You Love",
      description: "Share tracks with friends and help artists grow.",
      iconImage: "/song_recommending_icon_02.svg",
    },
    {
      image: "/mock_app_promo.png",
      title: "PROMOTION TOOLS",
      header: "Amplify Your Reach",
      description: "Get your music heard in the right places.",
      iconImage: "/promo_icon.png",
    },
    {
      image: "/mockup_app_fanalert.png",
      title: "FAN ALERTS",
      header: "Notify Your Fans Instantly",
      description: "Let your fans know the moment you drop something new.",
      iconImage: "/fanalerticon.png",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % mockupFeature.length);
    }, 3000); 

    return () => clearInterval(interval);
  }, [mockupFeature.length]);

  const current = mockupFeature[currentIndex];

  return (
    <section className="augio-region-info-container">
      <div className="augio-region-fade-wrapper" key={currentIndex}>
        <img
          src={current.image}
          alt={current.title}
          className="augio-region-app-image"
        />

        <div className="augio-region-text-group">
          <img src={current.iconImage} alt="Map" className="augio-map-image" />
          <h2 className="augio-region-info-title">{current.title}</h2>
          <h3 className="augio-region-info-header">{current.header}</h3>
          <p className="augio-region-info-subtext">{current.description}</p>
        </div>
      </div>
    </section>
  );
};
