interface File {
  type: string;
  size: number;
}

export const validateCoverArt = (file: File | null): string => {
  if (!file) {
    return "Cover art required";
  }

  const validTypes = ["image/jpeg", "image/png"];
  if (!validTypes.includes(file.type)) {
    return "File must be a jpg or png";
  }

  return "";
};

export const validateTrackTitle = (trackTitle: string): string => {
  if (!trackTitle.trim()) {
    return "Track title cannot be blank";
  }

  return "";
};

export const validateAlbumTitle = (albumTitle: string): string => {
  if (!albumTitle.trim()) {
    return "Album title cannot be blank";
  }

  return "";
}

export const validateGenre = (genre: string | null): string => {
  if (!genre) {
    return "Must select a genre";
  }

  return "";
};

export const validateAudioFile = (file: File | null): string => {
  if (!file) {
    return "Missing audio file";
  }

  console.log("Uploaded file type:", file.type);

  // normalize MIME type to lowercase
  const fileType = file.type.toLowerCase();

  // expanded list of accepted MIME types
  const validTypes = [
    "audio/mpeg",
    "audio/mp3",
    "audio/wav",
    "audio/x-wav",
    "audio/wave",
    "audio/vnd.wave"
  ];

  if (!validTypes.includes(fileType)) {
    return "Attached file type is not accepted - must be mp3 or wav";
  }

  if (file.size > 2500000000) {
    return "File must be under 2.5GB";
  }

  return "";
};


export const validateUploadTermsCheckBox = (terms: boolean): string => {
  if (!terms) {
    return "Must agree to terms";
  }
  return "";
}

