import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, query, where, orderBy, limit } from "firebase/firestore";
import { firestore } from "../firebase";
import "../Styles/TrendingRegions.css";
import { Region, Track } from "../Types/Types"



export const TrendingRegions = () => {
  const [regions, setRegions] = useState<(Region & { coverArtUrl?: string })[]>(
    []
  );
  const navigate = useNavigate();

  // Function to shuffle an array using Fisher-Yates algorithm
  const shuffleArray = (array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
  };

  useEffect(() => {
    const fetchAndRankRegions = async () => {
      // Aggregate track counts by regionID
      const tracksSnapshot = await getDocs(collection(firestore, "tracks"));
      const trackCountsByRegion: Record<string, number> = {};

      tracksSnapshot.forEach((doc) => {
        const track = doc.data();
        if (track.regionID) {
          trackCountsByRegion[track.regionID] =
            (trackCountsByRegion[track.regionID] || 0) + 1;
        }
      });

      // Fetch region details and sort by track count
      const regionsPromises = Object.entries(trackCountsByRegion).map(
        async ([regionID, count]) => {
          const numericRegionID = Number(regionID);
          const regionQuery = query(
            collection(firestore, "regions"),
            where("regionID", "==", numericRegionID)
          );
          const querySnapshot = await getDocs(regionQuery);

          let coverArtUrl = "";

          if (!querySnapshot.empty) {
            const regionData = querySnapshot.docs[0].data();
            // Query tracks collection for the most recent track by releaseDate for region
            const tracksQuery = query(
              collection(firestore, "tracks"),
              where("regionID", "==", numericRegionID),
              orderBy("releaseDate", "desc"),
              limit(1)
            );
            const tracksSnapshot = await getDocs(tracksQuery);
            if (!tracksSnapshot.empty) {
              const mostRecentTrackData = tracksSnapshot.docs[0].data();
              coverArtUrl = mostRecentTrackData.coverArtUrl || "";
            }

            return {
              id: querySnapshot.docs[0].id,
              regionName: regionData.regionName,
              regionNameAlias: regionData.regionNameAlias,
              trackCount: count,
              regionImage: regionData.regionImage,
              coverArtUrl,
            };
          }
          return null;
        }
      );

      const fetchedRegions = (await Promise.all(regionsPromises)).filter(
        Boolean
      ) as (Region & { coverArtUrl?: string })[];

      // Shuffle the regions order randomly
      shuffleArray(fetchedRegions);

      setRegions(fetchedRegions.slice(0, 6)); // Keep top 6 random regions
    };

    fetchAndRankRegions();
  }, []);

  const handleNavigate = (regionNameAlias: string | undefined) => {
    if (regionNameAlias) {
      navigate(`/region/${regionNameAlias}`);
    } else {
      console.log("Region name alias is undefined, navigation aborted.");
      // need to add error fallback here
    }
  };

  return (
    <div className="trending-regions-container">
      {regions.map((region) => (
        <div
          key={region.id}
          className="trending-region-item"
          onClick={() => handleNavigate(region.regionNameAlias)}
        >
          <div className="trending-region-image">
            <div className="region-cover-background-overlay"></div>
            <img
              className="region-cover-art"
              src={region.coverArtUrl}
              alt="Cover Art"
            />
            <img className="region-cover-image" src={region.regionImage}></img>
          </div>
          <p className="trending-region-name">{region.regionName}</p>
        </div>
      ))}
    </div>
  );
};
