import React, { useState, useEffect } from "react";
import { Toolbar } from "./Toolbar";
import { SideNavigation } from "./SideNavigation";
import { AudioTrack } from "./AudioTrack";
import { Feed } from "./Feed";
import { AudioUpload } from "./AudioUpload";
import { AudioPlayer } from "./AudioPlayer";
import { TrendingCharts } from "./TrendingCharts";
import { TrendingRegions } from "./TrendingRegions";
import "../Styles/Home.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import { IoMdCloseCircle } from "react-icons/io";

export const Home = () => {
  const { currentUser, userType } = useAuth();
  const navigate = useNavigate();
  const [showInfoMessage, setShowInfoMessage] = useState(true);


   useEffect(() => {
     const dismissed = sessionStorage.getItem("dismissedInfoMessage");
     if (dismissed === "true") {
       setShowInfoMessage(false);
     }
   }, []);

   const handleCloseMessage = () => {
     setShowInfoMessage(false);
     sessionStorage.setItem("dismissedInfoMessage", "true");
   };


  return (
    <div className="page-container">
      <section className="inner-page-layout">
        <section className="inner-page-content">
          {userType === "Artist" && showInfoMessage && (
            <div className="info-message-container">
              <p className="info-message-text">
                Download the Augio iOS app to access features like music
                promotions and fan alerts!
              </p>
              <IoMdCloseCircle
                className="info-message-close-btn"
                onClick={handleCloseMessage}
              />
            </div>
          )}

          {userType === "Artist" && showInfoMessage && (
            <div className="info-upload-message-container">
              <p className="info-upload-message-text">
                To upload or edit songs, please log in on your computer.
              </p>
              <IoMdCloseCircle
                className="info-upload-message-close-btn"
                onClick={handleCloseMessage}
              />
            </div>
          )}

          <div className="header-titles">Trending Charts</div>
          <TrendingCharts />
          <div className="header-titles">Trending Regions</div>
          <TrendingRegions />
          <div className="header-titles">Latest Releases</div>
          <section>
            <Feed />
            <section></section>
          </section>
          {userType === "Artist" && (
            <>
              <div className="header-titles">My Uploads</div>
              <section>
                <section>
                  <AudioUpload />
                </section>
              </section>
            </>
          )}
        </section>
      </section>
    </div>
  );
};
