//REACT IMPORTS
import { Link } from "react-router-dom";

//STYLES
import "../Styles/StateChartsFeature.css";

export const StateChartsFeature = () => {
  const stateImage_01: string = "/texas_outline.png";
  const stateImage_02: string = "/tenn_outline.png";
  const stateImage_03: string = "/mich_outline.png";
  const stateImage_04: string = "/cali_outline.png";


  return (
    <section className="statecharts-feature-container">
      <div className="statecharts-images-stack">
        <img src={stateImage_01} className="coverart_stateoutline img-01" />
        <img src={stateImage_02} className="coverart_stateoutline img-02" />
        <img src={stateImage_03} className="coverart_stateoutline img-03" />
        <img src={stateImage_04} className="coverart_stateoutline img-04" />
      </div>

      <div className="statecharts-text-group">
        <h2 className="statechart-feature-headline">
          Discover the <br></br>Next Big Artist.<br></br>Every Day.
        </h2>
        <h3 className="statechart-feature-subtext">
          State charts update daily
        </h3>
        <div className="statechart-button-wrapper">
          <Link to="/register" className="statechart-feature-info-text">
            Start Exploring
          </Link>
        </div>
      </div>
    </section>
  );
};
