// FIREBASE
import { firestore, auth } from "../firebase";
import { doc, collection, addDoc, getDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

// EXTERNAL PACKAGES
import { v4 as uuidv4 } from "uuid";
import * as Sentry from '@sentry/react';


interface UploadURLResponse {
  url: string;
}

const BASE_URL = "https://augio.io";


export class UploadSingleService {
  setIsLoading: (isLoading: boolean) => void;

  constructor(setIsLoading: (isLoading: boolean) => void) {
    this.setIsLoading = setIsLoading;
  }

  sanitizeTitle(title: string): string {
    return title.toLowerCase().split(" ").filter(Boolean).join("-");
  }

  async uploadMusic(form: any): Promise<void> {
    this.setIsLoading(true);

    try {
      const user = auth.currentUser;
      if (!user) throw new Error("User must be authenticated to upload music.");

      // Fetch user's stateID and regionID from Firestore
      const userDocRef = doc(firestore, "users", user.uid);
      const userDoc = await getDoc(userDocRef);
      if (!userDoc.exists()) throw new Error("We couldn't find your user record. Please try again.");

      const { stateID, regionID, userName, artistName } = userDoc.data();

      // First, confirm the file objects are received correctly
      console.log("Cover Art File Object:", form.coverArt);
      console.log("Audio File Object:", form.audioFile);

      // Debugging the environment variable just before constructing URLs
      console.log("S3 Bucket Name:", process.env.REACT_APP_S3_BUCKET_NAME);

      // Generate unique filenames
      const coverArtFileName = form.coverArt ? `${uuidv4()}_${form.coverArt.name}` : "";
      const audioFileName = form.audioFile ? `${uuidv4()}_${form.audioFile.name}` : "";


      // Debugging filenames after generation
      console.log("Generated coverArtFileName:", coverArtFileName);
      console.log("Generated audioFileName:", audioFileName);

      // Conditional uploads based on file presence
      if (form.coverArt) await this.uploadFile(form.coverArt, coverArtFileName, "Cover Art");
      if (form.audioFile) await this.uploadFile(form.audioFile, audioFileName, "Audio File");

      // Construct URLs for uploaded files
      const coverArtUrl = coverArtFileName ? `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.amazonaws.com/${coverArtFileName}` : "";
      const audioFileUrl = audioFileName ? `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.amazonaws.com/${audioFileName}` : "";

      if (form.coverArt && !coverArtUrl) {
        throw new Error("Cover art upload failed. Please try again.");
      }
      if (form.audioFile && !audioFileUrl) {
        throw new Error("Audio file upload failed. Please try again.");
      }

      // Sanitize the track title and generate the audio track link
      const sanitizedTrackTitle = this.sanitizeTitle(form.trackTitle);
      const trackLink = `${BASE_URL}/${userName}/${sanitizedTrackTitle}`;


      const trackData = {
        trackTitle: form.trackTitle,
        featureArtists: form.featureArtists,
        genre: form.genre,
        coverArtUrl,
        audioFileUrl,
        producers: form.producers,
        songwriters: form.songwriters,
        engineers: form.engineers,
        releaseDate: form.releaseDate,
        uploadTimestamp: new Date(),
        uploadType: form.uploadType,
        allTimePlays: 0,
        last24HoursPlays: 0,
        last7DaysPlays: 0,
        likes: 0,
        userId: user.uid,
        stateID,
        regionID,
        userName,
        artistName,
        trackLink,
        recordLabel: form.recordLabel
      };

      await addDoc(collection(firestore, "tracks"), trackData);
      this.setIsLoading(false);
    } catch (error) {
      console.error("Upload failed:", error);
      this.setIsLoading(false);
      throw error;
    }
  }

  async uploadFile(file: File, fileName: string, fileType: string): Promise<string> {
    const functions = getFunctions();
    const generateUploadURL = httpsCallable<unknown, { signedUrl: string; objectUrl: string }>(functions, "generateUploadURL");

    this.setIsLoading(true);
    const uploadStartTime = Date.now();

    try {
      const { data } = await generateUploadURL({ filename: fileName });
      const { signedUrl, objectUrl } = data;

      const response = await fetch(signedUrl, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type || "application/octet-stream",
        },
      });

      const uploadDuration = Date.now() - uploadStartTime;

      if (!response.ok) {
        throw new Error(`Failed to upload ${fileType}. HTTP Status: ${response.status}`);
      }

      //breadcrumbs used for what action taken before failure
      Sentry.addBreadcrumb({
        category: "single upload",
        message: `${fileType} uploaded successfully`,
        level: "info",
        data: {
          fileName,
          fileSize: file.size,
          fileType: file.type,
          uploadDuration,
          userId: auth?.currentUser?.uid,
        },
      });

      /*
      //capture message creates actual event log
      Sentry.captureMessage(`${fileType} single uploaded successfully`, {
        level: "info",
        tags: {
          uploadStatus: "success",
          fileType,
        },
        extra: {
          fileName,
          fileSize: file.size,
          fileMimeType: file.type,
          uploadDuration,
          userId: auth?.currentUser?.uid,
        },
      });
      */

      return objectUrl;
    } catch (error: any) {
      const uploadDuration = Date.now() - uploadStartTime;

      Sentry.captureException(error, {
        tags: {
          uploadStatus: "single upload failed",
          fileType,
        },
        extra: {
          message: `${fileType} single upload failed`,
          fileName,
          fileSize: file.size,
          fileMimeType: file.type,
          uploadDuration,
          userId: auth?.currentUser?.uid,
        },
      });

      throw error;
    } finally {
      this.setIsLoading(false);
    }
  }

}