// REACT IMPORTS
import { useEffect, useState, useRef } from "react";

// ICONS
import { BsLinkedin } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import {
  FaTiktok,
  FaLink,
  FaMedium,
  FaYoutube,
  FaInstagramSquare,
} from "react-icons/fa";

//STYLES
import "../Styles/AugioStatements.css";

export const AugioStatements = () => {
  const statements = [
    {
      header: "AUGIO: EMPOWERING INDEPENDENT ARTISTS AND MUSIC FANS",
      bigText: "AN EXPLOSION OF MUSIC, A CRISIS OF VISIBILITY",
      subtext:
        "More than 100,000 tracks are uploaded daily to streaming platforms, and 96% are from independent artists. Yet, existing platforms prioritize major label artists, leaving independent artists' music behind.",
    },
    {
      header: "THE STATUS QUO: FAVORING THE FEW",
      bigText: "STREAMING IS BUILT FOR MAJOR LABELS",
      subtext:
        "Existing platforms cater to major label artists and their distributors. Independent artists lack an unbiased streaming platform that solely highlights their music, and fans miss out on discovering a world of new music.",
    },
    {
      header: "THE CHALLENGE: OVERSATURATION",
      bigText: "STANDING OUT IS NEARLY IMPOSSIBLE",
      subtext:
        "With 100,000+ tracks flooding platforms daily, independent artists struggle to break through algorithms that are designed to promote the music of major labels.",
    },
    {
      header: "FOR MUSIC FANS",
      bigText: "A LACK OF TRUE DISCOVERY",
      subtext:
        "Fans are forced to rely on pre-curated playlists and algorithms, often defaulting to familiar artists. Genuine discovery feels missing, limiting the connection to fresh, independent music.",
    },
    {
      header: "A BROKEN SYSTEM",
      bigText: "ORGANIC DISCOVERY REDEFINED",
      subtext:
        "Paid playlist spots, label-owned curation, and obscure algorithms dominate streaming. Augio levels the playing field by championing fair and transparent music discovery.",
    },
    {
      header: "BRINGING BACK THE FUN OF MUSIC DISCOVERY",
      bigText: "MAKING SHARING SIMPLE AND SOCIAL",
      subtext:
        "Sharing music should be effortless and social, not a tedious and multi-step process of copying links and sending messages. Augio transforms this into a seamless, social experience, reigniting the fun of discovering and sharing music.",
    },
  ];

  return (
    <div>
      <h2 className="statements-header">FIXING WHAT'S BROKEN</h2>

      <section className="augio-statements-container">
        {statements.map((statement, index) => (
          <div className="statement-wrapper" key={index}>
            <div className="gradient-border">
              <div className="statement-container">
                <p className="statement-header">{statement.header}</p>
                <p className="statement-bigtext">{statement.bigText}</p>
                <p className="statement-subtext">{statement.subtext}</p>
              </div>
            </div>
            <FaLink
              className={`link-icon ${index === 6 ? "mobile-chain-link" : ""}`}
            />
          </div>
        ))}
      </section>
    </div>
  );
};
