// REACT IMPORTS
import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

// STYLES
import "../Styles/Landing.css";

// FIREBASE
import { httpsCallable } from "firebase/functions";
import { collection, addDoc } from "firebase/firestore";
import { functions, firestore } from "../firebase";

// CONTEXT
import { useAuth } from "../Context/AuthContext";

// COMPONENTS
import { AugioStatements } from "../SubComponents/AugioStatements";
import { AugioFeatures } from "../SubComponents/AugioFeatures";
import { LandingHeader } from "../SubComponents/LandingHeader";
import { Footer } from "../SubComponents/Footer";
import { SocialFeatures } from "../SubComponents/SocialFeatures";
import { AugioHighlights } from "../SubComponents/AugioHighlights";
import { StateChartsFeature } from "../SubComponents/StateChartsFeature";

// UTILITIES
import { logCustomEvent } from "../Utilities/EventConfig";


export const LandingPage = () => {
  const gLogoUrl: string = "/Augio_G_Avatar_latest.png";
  const jmPic: string = "/jm_pic.jpg";
  const iOSAppStoreDownloadImage: string = "/iosappstoreimage.png";
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate("/home");
    }
  }, [currentUser, navigate]);

  return (
    <div>
      <div className="landing-page-container">
        <div>
          <LandingHeader />

          <AugioStatements />

          <AugioFeatures />

          <section className="landing-page-mobile-app-download-container">
            <p className="landing-header-app-download-text">
              DOWNLOAD THE AUGIO IOS APP
            </p>
            <a
              href="https://apps.apple.com/us/app/augio-music-discovery/id6736578132"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                logCustomEvent("mobile_app_dl_button_click", {
                  button_name: "Mobile App Download",
                  page_location: window.location.pathname,
                })
              }
              title="Download the Augio Mobile App"
              aria-label="Download the Augio Mobile App"
            >
              <img
                className="appstoredownload-image"
                src={iOSAppStoreDownloadImage}
              ></img>
            </a>
          </section>

          <SocialFeatures />

          <StateChartsFeature />

          <AugioHighlights />

          <section className="quote-section">
            <img className="jm-pic" src={jmPic} />
            <p className="founder-quote">
              "We built Augio to be the true home of independent music, a place
              where independent artists can stand out, be discovered, and access
              powerful tools to grow their fanbase without being overshadowed by
              major label artists. It's a platform where their music is
              genuinely highlighted and celebrated. At the same time, we set out
              to give music fans fresh, fun, and social ways to discover and
              share music, all while creating a next-generation streaming
              experience built around discovery."
            </p>
            <p className="quote-credit">Joe Murphy</p>
            <p className="quote-credit">Founder of Augio</p>
          </section>
        </div>
      </div>

      <Footer />
    </div>
  );
};
