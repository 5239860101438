//STYLES
import "../Styles/SocialFeatures.css";

export const SocialFeatures = () => {
  const recommendIcon: string = "/song_recommending_icon_02.svg";
  const groupshareIcon: string = "/groupsharemusic_icon.png";
  const recommendScreenshots: string = "/sidebyside_mock_01.png";
  const friendsImage: string = "/friends_image copy.jpg";

  return (
    <div className="page-center">
      <h2 className="socialfeature-header">
        PUT YOUR FRIENDS ON TO NEW MUSIC.
        <br />
        RIGHT IN THE APP.
      </h2>
      <section className="manual-layout">
        <div className="row top-row">
          <div className="box top-left">
            <div className="box-content-largecard">
              <h3 className="box-header-largecard">PASS THE AUX</h3>
              <p className="box-subtext-largecard">
                Send songs to friends directly in the app
              </p>
              <img
                className="sidebyside-mockup"
                src={recommendScreenshots}
                alt="Share icon"
              />
            </div>
          </div>

          <div className="box top-right">
            <div className="box-content-smallcard">
              <h3 className="box-header-smallcard">
                <span style={{ color: "#61dbc0" }}>Add friends.</span>
                <br />
                <span style={{ color: "#FFFFFF" }}>Play music.</span>
                <br />
                <span style={{ color: "#A665FF" }}>Send songs.</span>
              </h3>

              <h3 className="box-subtext-smallcard">
                Share songs in-app. No more text links.
              </h3>
              <p className="box-subtext-smallcard">
                A feed just for music from friends.
              </p>

              <img
                className="recommend-icon-image"
                src={recommendIcon}
                alt="Share icon"
              />
            </div>
          </div>
        </div>
        <div className="row bottom-row">
          <div className="box bottom-left">
            <div className="box-content-smallcard">
              <h3 className="box-header-smallcard">
                <span style={{ color: "#61dbc0" }}>Tap.</span>
                <br />
                <span style={{ color: "#FFFFFF" }}>Share.</span>
                <br />
                <span style={{ color: "#A665FF" }}>Repeat.</span>
              </h3>
              <h3 className="box-subtext-largecard">
                Your music circle, in one place.
              </h3>

              <img
                className="groupsharemusic-icon"
                src={groupshareIcon}
                alt="Share icon"
              />
            </div>
          </div>
          <div className="box bottom-right">
            <div className="box-content-largecard">
              <h3 className="box-header-largecard">MUSIC SHARING, MADE FUN</h3>
              <p className="box-subtext-largecard">
                Send songs to friends directly
              </p>
              <img
                className="friends-image"
                src={friendsImage}
                alt="Share icon"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
