import "../Styles/Faq.css";
import { Link } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { useState, useEffect } from "react";
import FaqInfo from "../Utilities/FaqInfo.json";
import "../Styles/Terms.css";
import "../Styles/Landing.css";
import { FaLink, FaMedium, FaYoutube } from "react-icons/fa";
import { BsLinkedin } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";

export const Faq = () => {
  const logoUrl: string = "/Augio_FullType_Logo_latest.png";
  const [openFaq, setOpenFaq] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleFaq = (faqId: number) => {
    setOpenFaq(openFaq === faqId ? null : faqId);
  };

  const filteredFaqs = FaqInfo.filter((faq) =>
    faq.faqTitle.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <section className="faq-container">
        <Link to="/">
          <img
            className="augio-logo-terms-page"
            src={logoUrl}
            alt="Augio Logo"
          />
        </Link>

        <h2 className="faq-title-text">FAQ</h2>
        <h3 className="faq-subtitle-text">
          Find common questions and answers about Augio
        </h3>

        <input
          type="text"
          className="faq-search-input"
          placeholder="Search FAQs..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <section className="faq-list">
          {filteredFaqs.length > 0 ? (
            filteredFaqs.map((faq) => (
              <div
                key={faq.faqId}
                className={`faq-item ${
                  openFaq === faq.faqId ? "expanded" : ""
                }`}
              >
                <div
                  className="faq-header"
                  onClick={() => toggleFaq(faq.faqId)}
                >
                  <p>{faq.faqTitle}</p>
                  <IoIosArrowDown
                    className={`arrow-icon ${
                      openFaq === faq.faqId ? "open" : ""
                    }`}
                  />
                </div>
                {openFaq === faq.faqId && (
                  <p className="faq-description">{faq.faqDescription}</p>
                )}
              </div>
            ))
          ) : (
            <p className="faq-no-results">No FAQs match your search.</p>
          )}
        </section>
      </section>

      <footer>
        <div className="footer-container">
          <div className="footer-top-group">
            <img
              className="footer-text-logo"
              src={logoUrl}
              alt="augio-colorized-mock"
            />

            <div>
              <Link className="footer-button-link fbl-login" to="/login">
                Login
              </Link>
              <Link className="footer-button-link fbl-register" to="/register">
                Register
              </Link>
              <a
                className="footer-button-link fbl-mobile"
                href="https://apps.apple.com/us/app/augio-music-discovery/id6736578132"
                target="_blank"
                rel="noopener noreferrer"
              >
                Mobile App
              </a>
            </div>
          </div>

          <hr className="footer-divider"></hr>

          <div className="footer-bottom-group">
            <div className="footer-company-resources">
              <div className="footer-links-group">
                <h6>COMPANY</h6>
                <p className="footer-link">
                  <Link
                    className="footer-link clickable-link"
                    target="_blank"
                    to="https://augio.medium.com/"
                  >
                    Blog
                  </Link>
                </p>

                <p className="footer-link">Press: info@augio.io</p>
                <p className="footer-link">Support: support@augio.io</p>
              </div>

              <div className="footer-links-group">
                <h6>RESOURCES</h6>
                <Link
                  className="footer-link quick-link"
                  target="_blank"
                  to="/terms"
                >
                  Terms
                </Link>
                <Link
                  className="footer-link quick-link"
                  target="_blank"
                  to="/membership-terms"
                >
                  Membership Agreement
                </Link>

                <Link
                  className="footer-link quick-link"
                  target="_blank"
                  to="/privacy-policy"
                >
                  Privacy Policy
                </Link>

                <Link className="footer-link quick-link" to="/faq">
                  FAQ
                </Link>
              </div>
            </div>

            <div className="footer-social-group">
              <div className="footer-icons">
                <a
                  className="footer-link"
                  href="https://www.instagram.com/augio.io"
                  target="_blank"
                >
                  <AiFillInstagram className="footer-icon"></AiFillInstagram>
                </a>
                <a
                  className="footer-link quick-link"
                  href="https://www.linkedin.com/company/augiomusic"
                  target="_blank"
                >
                  <BsLinkedin className="footer-icon"></BsLinkedin>
                </a>
                <a
                  className="footer-link"
                  href="https://www.tiktok.com/@augio.io"
                  target="_blank"
                >
                  <FaTiktok className="footer-icon"></FaTiktok>
                </a>
                <a
                  className="footer-link quick-link"
                  href="https://www.youtube.com/@Augio-io"
                  target="_blank"
                >
                  <FaYoutube className="footer-icon"></FaYoutube>
                </a>
                <a
                  className="footer-link quick-link"
                  href="https://augio.medium.com/"
                  target="_blank"
                >
                  <FaMedium className="footer-icon"></FaMedium>
                </a>
              </div>

              <div className="augio-copyright">
                © 2025 AUGIO. All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
