import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; 
import { collection, getDocs, query, where, getDoc, doc } from "firebase/firestore";
import { firestore } from "../firebase"; 
import "../Styles/TrendingCharts.css";
import { Chart, Track } from "../Types/Types"; 




export const TrendingCharts = () => {
  const [charts, setCharts] = useState<(Chart & { coverArtUrl?: string })[]>(
    []
  );
  const navigate = useNavigate();

  // Function to shuffle an array using Fisher-Yates algorithm
  const shuffleArray = (array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
  };

  useEffect(() => {
    const fetchAndSortCharts = async () => {
      const chartsRef = collection(firestore, "stateCharts");
      const q = query(chartsRef);
      const querySnapshot = await getDocs(q);

      let chartsData = await Promise.all(
        querySnapshot.docs.map(async (docSnapshot) => {
          const chartData: Chart & { coverArtUrl?: string } = {
            ...(docSnapshot.data() as Chart),
            id: docSnapshot.id,
          };

          if (chartData.chartTrackIDs && chartData.chartTrackIDs.length > 0) {
            // Fetch the cover art for the first track
            const trackRef = doc(
              firestore,
              "tracks",
              chartData.chartTrackIDs[0]
            );
            const trackSnap = await getDoc(trackRef);
            if (trackSnap.exists()) {
              const trackData = trackSnap.data() as Track;
              chartData.coverArtUrl = trackData.coverArtUrl;
            }
          }

          return chartData;
        })
      );

      // Filter charts without tracks
      chartsData = chartsData.filter(
        (chart) => chart.chartTrackIDs && chart.chartTrackIDs.length > 0
      );

      // Shuffle the charts order randomly
      shuffleArray(chartsData);

      setCharts(chartsData.slice(0, 6)); // Keep top 6 random charts
    };

    fetchAndSortCharts();
  }, []);

  const handleNavigate = (stateNameAlias: string) => {
    navigate(`/chart/${stateNameAlias}`);
  };

  return (
    <div className="trending-charts-container">
      {charts.map((chart) => (
        <div
          key={chart.id}
          className="trending-chart-item"
          onClick={() => handleNavigate(chart.stateNameAlias)}
        >
          <div className="trending-chart-image">
            <div className="chart-cover-background-overlay"></div>
            <img
              className="chart-cover-art"
              src={chart.coverArtUrl}
              alt="Cover Art"
            />
            <img className="state-cover-image" src={chart.stateImage}></img>
          </div>
          <p className="trending-chart-name">{chart.stateName}</p>
        </div>
      ))}
    </div>
  );
};
