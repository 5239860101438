import React, { useEffect, useState } from "react";
import { FaRegMoon } from "react-icons/fa";
import { IoSunnyOutline } from "react-icons/io5";

const ThemeToggle = () => {
  const [theme, setTheme] = useState(() => {
    return localStorage.getItem("theme") || "light";
  });

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);

    // Override system preference using prefers-color-scheme
    document.documentElement.style.setProperty("color-scheme", newTheme);
  };

  useEffect(() => {
    // Apply saved theme on load
    document.documentElement.style.setProperty("color-scheme", theme);
  }, [theme]);

  return (
    <button onClick={toggleTheme} className="darkmode-icon">
      {theme === "light" ? (
        <FaRegMoon className="darkmode-icon" />
      ) : (
        <IoSunnyOutline className="lightmode-icon" />
      )}
    </button>
  );
};

export default ThemeToggle;
