//REACT IMPORTS
import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// COMPONENTS
import { logCustomEvent } from "../Utilities/EventConfig";

//STYLES
import "../Styles/LandingHeader.css";

export const LandingHeader = () => {
  const gLogoUrl: string = "/Augio_G_Avatar_latest.png";
  const augioLogo: string = "/Augio_FullType_Logo_latest.png";
  const map_outline: string = "/us_map_03.png";

  return (
    <div className="landing-header-container">
      <section className="landing-toolbar">
        <a
          className="landing-toolbar-link landing-mobile-link"
          href="https://apps.apple.com/us/app/augio-music-discovery/id6736578132"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            logCustomEvent("mobile_app_dl_button_click", {
              button_name: "Mobile App Download",
              page_location: window.location.pathname,
            })
          }
        >
          MOBILE APP
        </a>
        <Link className="landing-toolbar-link landing-login-link" to="/login">
          LOGIN
        </Link>
        <Link
          className="landing-toolbar-link landing-register-link"
          to="/register"
        >
          REGISTER
        </Link>
      </section>

      <section className="landing-header-logo-container">
        <img className="landing-header-logo" src={augioLogo}></img>
        <div className="map-outline-container">
          <img className="map-outline" src={map_outline} />
          <h2 className="map-overlay-text">
            PUTTING <br />
            INDEPENDENT MUSIC <br />
            ON THE MAP
          </h2>
        </div>
      </section>

      <section className="landing-header-about-container">
        <h4 className="landing-header-info-text">
          Current music streaming platforms face mounting challenges:
          oversaturation, biased partnerships, and obscure algorithms that favor
          major labels, leaving independent artists and fans behind. Augio is
          here to change that.
        </h4>
        <h3 className="landing-header-about-text">
          Augio is a location-based music streaming and discovery platform exclusive to
          independent artists
        </h3>
      </section>
    </div>
  );
};
