// REACT IMPORTS
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// STYLES
import "../Styles/Login.css";

// FIREBASE
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

// CONTEXT
import { useAuth } from "../Context/AuthContext";

// SERVICES
import { validateEmail, validatePassword } from "../Services/LoginValidation";

// COMPONENTS
import { Spinner } from "../GlobalStyles/Spinner";

// UTILITIES
import { logCustomEvent } from "../Utilities/EventConfig";



export const Login = () => {
  const logoImage: string = "/Augio_FullType_Logo_latest.png";
  const { currentUser } = useAuth();
  const [formValues, setFormValues] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    if (currentUser) {
      navigate("/home");
    }
  }, [currentUser, navigate]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const { email, password } = formValues;

    setErrors({ email: "", password: "" });

    const emailError = validateEmail(email);
    const passwordError = validatePassword(password);
    if (emailError || passwordError) {
      setErrors({ email: emailError, password: passwordError });
      setIsLoading(false);
      return;
    }

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/home");
      logCustomEvent("Login Event");
    } catch (error) {
      console.error("Login failed", error);
      setErrors({ email: "", password: "Incorrect credentials" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-form-container">
      <Link className="login-logo-link" to="/">
        <img src={logoImage} alt="Logo" className="login-logo" />
      </Link>
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="login-header-text">Sign In</div>

        <label className="create-account-form-label">
          Email<span style={{ color: "#D42616" }}></span>
        </label>
        <input
          className="login-form-input"
          name="email"
          type="email"
          value={formValues.email}
          onChange={handleInputChange}
        />
        {errors.email && <span className="error-message">{errors.email}</span>}

        <label className="create-account-form-label">
          Password<span style={{ color: "#D42616" }}></span>
        </label>
        <input
          className="login-form-input"
          name="password"
          type="password"
          value={formValues.password}
          onChange={handleInputChange}
        />
        {errors.password && (
          <span className="error-message">{errors.password}</span>
        )}
        <Link className="forgot-password-link" to="/forgot-password">
          Forgot Password
        </Link>
        <button className="login-form-btn" type="submit">
          Login
        </button>

        {isLoading && (
          <div className="overlay">
            <Spinner />
          </div>
        )}
      </form>
    </div>
  );
};
